<template>
    <div class="modal" v-if="modelValue" :class="{active : modelValue}"  @click.self="closeModal()">
        <div class="modal_container">
            <div class="modal_header border">
                <div class="modal_info">
                    <h1>Merge contacts</h1>
                </div>
                <button class="close_btn left_out" @click="closeModal()"><i class="fas fa-long-arrow-alt-right"></i></button>
            </div>
            <div class="modal_body">
                <div class="setting_wpr">
                    <Form @submit="handleMergeContacts" v-slot="{ errors }" ref="merge-contact-form">
                        <div class="form_grp">
                            <div class="group_item">
                                <label class="input_label">Choose Join Date</label>
                                <div class="field_wpr" :class="{ 'has-error': errors.join_date }">
                                    <Field autocomplete="off" name="join_date" v-model="form.contact_primary.date_created" rules="required" label="join date">
                                        <multiselect
                                            v-model="form.contact_primary.date_created"
                                            label="date_created"
                                            :searchable="true"
                                            value-prop="date_created"
                                            :options="selectedContacts"
                                            placeholder="Select"
                                            autocomplete="nofill"
                                        ></multiselect>
                                    </Field>
                                </div>
                                <ErrorMessage name="join_date" class="text-danger" />
                            </div>
                            <div class="group_item">
                                <label class="input_label">Choose Primary Name</label>
                                <div class="field_wpr" :class="{ 'has-error': errors.name }">
                                    <Field autocomplete="off" name="name" v-model="form.contact_primary.name" rules="required">
                                        <multiselect
                                            v-model="form.contact_primary.name"
                                            label="name"
                                            :searchable="true"
                                            value-prop="name"
                                            :options="selectedContacts"
                                            placeholder="Select"
                                            autocomplete="nofill"
                                        ></multiselect>
                                    </Field>
                                </div>
                                <ErrorMessage name="name" class="text-danger" />
                            </div>
                        </div>
                        <div class="form_grp">
                            <div class="group_item">
                                <label class="input_label">Choose Primary Email</label>
                                <div class="field_wpr" :class="{ 'has-error': errors.email }">
                                    <Field autocomplete="off" name="email" v-model="form.contact_primary.email" rules="required">
                                        <multiselect
                                            v-model="form.contact_primary.email"
                                            label="email"
                                            :searchable="true"
                                            value-prop="email"
                                            :options="selectedContacts"
                                            placeholder="Select"
                                            autocomplete="nofill"
                                        >
                                            <template v-slot:singlelabel="{ value }">
                                                <div class="multiselect-single-label">
                                                    {{ value.email }}
                                                </div>
                                            </template>
                                            <template v-slot:option="{ option }">
                                                <span @click="handleEmail(option)">{{ option.email }}</span>
                                            </template>
                                        </multiselect>
                                    </Field>
                                </div>
                                <ErrorMessage name="email" class="text-danger" />
                            </div>
                        </div>
                        <div class="form_grp">
                            <div class="group_item">
                                <label class="input_label">Choose Primary Phone</label>
                                <div class="field_wpr" :class="{ 'has-error': errors.phone }">
                                    <Field autocomplete="off" name="phone" v-model="form.contact_primary.phone" :rules="phoneNumbers.length > 0 ? 'required' : ''">
                                        <multiselect
                                            v-model="form.contact_primary.phone"
                                            label="number"
                                            :searchable="true"
                                            value-prop="number"
                                            :options="selectedContacts"
                                            placeholder="Select"
                                            autocomplete="nofill"
                                        ></multiselect>
                                    </Field>
                                </div>
                                <ErrorMessage name="phone" class="text-danger" />
                            </div>
                        </div>
                        <div class="form_grp">
                            <div class="group_item">
                                <label class="input_label">Choose Primary DOB</label>
                                <div class="field_wpr" :class="{ 'has-error': errors.birthdate }">
                                    <Field autocomplete="off" name="birthdate" v-model="form.contact_primary.birthdate">
                                        <multiselect
                                            v-model="form.contact_primary.birthdate"
                                            label="birthdate"
                                            :searchable="true"
                                            value-prop="birthdate"
                                            :options="selectedContacts"
                                            placeholder="Select"
                                            autocomplete="nofill"
                                        ></multiselect>
                                    </Field>
                                </div>
                                <ErrorMessage name="birthdate" class="text-danger" />
                            </div>
                        </div>
                        <div class="form_grp">
                            <div class="group_item">
                                <label class="input_label">Choose Primary Address</label>
                                <div class="field_wpr" :class="{ 'has-error': errors.full_address }">
                                    <Field autocomplete="off" name="full_address" v-model="form.contact_primary.full_address" :rules="addresses.length > 0 ? 'required' : ''" label="primary address">
                                        <multiselect
                                            v-model="form.contact_primary.full_address"
                                            label="full_address"
                                            :searchable="true"
                                            value-prop="full_address"
                                            :options="selectedContacts"
                                            placeholder="Select"
                                            autocomplete="nofill"
                                        >
                                            <template v-slot:singlelabel="{ value }">
                                                <div class="multiselect-single-label">
                                                    {{ value.full_address }}
                                                </div>
                                            </template>
                                            <template v-slot:option="{ option }">
                                                <span @click="handleAddress(option)">{{ option.full_address }}</span>
                                            </template>
                                        </multiselect>
                                    </Field>
                                </div>
                                <ErrorMessage name="full_address" class="text-danger" />
                            </div>
                        </div>
                        <div class="form_grp">
                            <div class="group_item">
                                <label class="input_label">Choose Primary Tab</label>
                                <div class="field_wpr" :class="{ 'has-error': errors.status_tab }">
                                    <Field autocomplete="off" name="status_tab" v-model="form.contact_primary.status_tab" rules="required" label="primary tab">
                                        <multiselect
                                            v-model="form.contact_primary.status_tab"
                                            :groups="true"
                                            :searchable="true"
                                            :options="statusesGroup"
                                            valueProp="value"
                                            label="title"
                                            placeholder="Select status"
                                            :group-select="false"
                                            group-label="type"
                                            group-options="items"
                                            :group-hide-empty="true"
                                            autocomplete="nofill"
                                            :loading="statusTabsLoader"
                                        >
                                            <template v-slot:grouplabel="{ group }">
                                                <span class="status-tabs-header">{{ group.type }}</span>
                                            </template>
                                            <template v-slot:option="{ option }">
                                                <span>{{ option.title }}</span>
                                            </template>
                                        </multiselect>
                                    </Field>
                                </div>
                                <ErrorMessage name="status_tab" class="text-danger" />
                            </div>
                        </div>
                        <div v-for="(setting, i) in form.settings" :key="i" >
                            <label :for="`setting-${setting.name}`" class="switch_option capsule_btn" >
                                <h5>{{setting.label}}</h5>
                                <input type="checkbox"  :id="`setting-${setting.name}`" v-model="form.settings[i].value" :true-value="1" :false-value="0" hidden>
                                <div><span></span></div>
                            </label>
                        </div>
                        <div class="action_wpr mt-5">
                            <button :disabled="bulkActionLoader" type="button" class="btn cancel_btn" @click="closeModal()">Cancel</button>
                            <button :disabled="bulkActionLoader" class="btn save_btn"><i class="fa fa-spinner fa-spin" v-if="bulkActionLoader"></i>{{ bulkActionLoader ? ' Merging' : 'Merge' }}</button>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { mapState, mapActions } from 'vuex'

    export default {
        name: 'MergeContact',

        data () {
            return{
                form: {
                    contacts: [],
                    contact_primary: {
                        name: '',
                        email: '',
                        id: '',
                        phone: '',
                        address_contact_id: '',
                        status_tab: '',
                        date_created: '',
                        birthdate: '',
                        address: {
                            address: '',
                            city: '',
                            state: '',
                            country: '',
                            zipcode: '',
                        },
                    },
                    settings: [{
                            value: 1,
                            label: 'Combine all form data?',
                            name: 'forms'
                        },
                        {
                            value: 1,
                            label: 'Combine all notes?',
                            name: 'notes'
                        },
                        {
                            value: 1,
                            label: 'Combine all rewards?',
                            name: 'rewards'
                        },
                        {
                            value: 1,
                            label: 'Combine all chats?',
                            name: 'chats'
                        },
                        {
                            value: 1,
                            label: 'Combine all lifecycle data?',
                            name: 'lifecycle'
                        },
                        {
                            value: 1,
                            label: 'Combine all tags?',
                            name: 'tags'
                        },
                        {
                            value: 1,
                            label: 'Combine all sent emails?',
                            name: 'emails'
                        },
                        {
                            value: 1,
                            label: 'Combine all broadcasts?',
                            name: 'broadcasts'
                        },
                        {
                            value: 1,
                            label: 'Combine all sequences?',
                            name: 'sequences'
                        },
                        {
                            value: 1,
                            label: 'Combine all journeys?',
                            name: 'journeys'
                        },
                        {
                            value: 1,
                            label: 'Combine all pages?',
                            name: 'pages'
                        },
                        {
                            value: 1,
                            label: 'Combine all playbooks?',
                            name: 'playbooks'
                        },
                        {
                            value: 1,
                            label: 'Combine all templates?',
                            name: 'templates'
                        },
                        {
                            value: 1,
                            label: 'Combine all pipelines?',
                            name: 'pipelines'
                        },
                        {
                            value: 1,
                            label: 'Combine all tasks?',
                            name: 'tasks'
                        },
                        {
                            value: 1,
                            label: 'Combine all progress tracking?',
                            name: 'progress-tracking'
                        },
                        {
                            value: 1,
                            label: 'Combine all habit tracking?',
                            name: 'habit-tracking'
                        },
                    ],
                },
                phoneNumbers: [],
                addresses: [],
                selectedContacts: [],
            }
        },

        props: {
            modelValue: [ Boolean],
            contacts: Array,
            module: {
                type: String,
                default: 'contact'
            },
        },

        emits: ['update:modelValue'],

        watch: {
            modelValue(val) {
                if (val) {
                    const vm = this;

                    vm.resetForm();
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },
        },

        components:{
            Form,
            Field,
            ErrorMessage,
        },

        computed: mapState({
            statusesGroup: state => state.contactModule.statusesGroup,
            contactsList: state => state.contactModule.contacts,
            bulkActionLoader: state => state.contactModule.bulkActionLoader,
            statusTabsLoader: state => state.contactModule.statusTabsLoader,
            tagContacts: state => state.tagModule.contacts,
            groupContacts: state => state.groupModule.contacts,
        }),

        methods: {
            ...mapActions({
                mergeContacts: 'contactModule/mergeContacts',
            }),

            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            handleAddress (contact) {
                const vm = this;

                vm.form.contact_primary.address_contact_id = contact.id;
                vm.form.contact_primary.address = {
                    address:  contact.address,
                    city:     contact.city,
                    state:    contact.state,
                    country:  contact.country,
                    zipcode:  contact.zipcode,
                };
            },

            handleEmail (contact) {
                const vm = this;

                vm.form.contact_primary.id = contact.id;
            },

            handleMergeContacts (params, { setFieldError }) {
                const vm = this;

                vm.form.setFieldError = setFieldError;
                vm.form.closeModal    = vm.closeModal;

                vm.mergeContacts(vm.form);
            },

            resetForm () {
                const vm = this;

                vm.form = {
                    contacts:  vm.contacts,
                    contact_primary: {
                        name: '',
                        email: '',
                        id: '',
                        phone: '',
                        address_contact_id: '',
                        status_tab: '',
                        date_created: '',
                        birthdate: '',
                        address: {
                            address: '',
                            city: '',
                            state: '',
                            country: '',
                            zipcode: '',
                        },
                    },
                    settings: [{
                            value: 1,
                            label: 'Combine all form data?',
                            name: 'forms'
                        },
                        {
                            value: 1,
                            label: 'Combine all notes?',
                            name: 'notes'
                        },
                        {
                            value: 1,
                            label: 'Combine all rewards?',
                            name: 'rewards'
                        },
                        {
                            value: 1,
                            label: 'Combine all chats?',
                            name: 'chats'
                        },
                        {
                            value: 1,
                            label: 'Combine all lifecycle data?',
                            name: 'lifecycle'
                        },
                        {
                            value: 1,
                            label: 'Combine all tags?',
                            name: 'tags'
                        },
                        {
                            value: 1,
                            label: 'Combine all sent emails?',
                            name: 'emails'
                        },
                        {
                            value: 1,
                            label: 'Combine all broadcasts?',
                            name: 'broadcasts'
                        },
                        {
                            value: 1,
                            label: 'Combine all sequences?',
                            name: 'sequences'
                        },
                        {
                            value: 1,
                            label: 'Combine all journeys?',
                            name: 'journeys'
                        },
                        {
                            value: 1,
                            label: 'Combine all pages?',
                            name: 'pages'
                        },
                        {
                            value: 1,
                            label: 'Combine all playbooks?',
                            name: 'playbooks'
                        },
                        {
                            value: 1,
                            label: 'Combine all templates?',
                            name: 'templates'
                        },
                        {
                            value: 1,
                            label: 'Combine all pipelines?',
                            name: 'pipelines'
                        },
                        {
                            value: 1,
                            label: 'Combine all tasks?',
                            name: 'tasks'
                        },
                        {
                            value: 1,
                            label: 'Combine all progress tracking?',
                            name: 'progress-tracking'
                        },
                        {
                            value: 1,
                            label: 'Combine all habit tracking?',
                            name: 'habit-tracking'
                        },
                    ],
                },

                vm.selectedContacts = [];

                let allContact = [];

                if (vm.module == 'contact') {
                    allContact = vm.contactsList;
                } else if (vm.module == 'tag') {
                    allContact = vm.tagContacts;
                } else if (vm.module == 'group') {
                    allContact = vm.groupContacts;
                }

                allContact.data.forEach((contact) => {
                    if (vm.contacts.includes(contact.id)) {
                        if (contact.number && contact.number.trim()) {
                            vm.phoneNumbers.push(contact.number);
                        }

                        if (contact.full_address && contact.full_address.trim()) {
                            vm.addresses.push(contact.full_address);
                        }
                        vm.selectedContacts.push(contact);
                    }
                });
            }
        },
    }
</script>
